/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:16:26 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-12-Fr 11:09:52
 */
<template>
  <div class="myData">
    <h3 class="personal_title">
      <el-popover
        placement="bottom"
        title=""
        width="200"
        trigger="hover"
        content=""
      >
        <el-scrollbar style="height: 210px">
          <div
            class="courseList"
            v-for="item in courseList"
            :key="item.CourseId"
            @click="handleChange(item)"
          >
            {{ item.CourseName }}
          </div>
        </el-scrollbar>
        <div slot="reference" class="courseList_hover">{{ selectVal }}</div>
      </el-popover>
    </h3>
    <ul class="types">
      <li
        v-for="(item, i) in types"
        :class="FileType == item.Id ? 'active' : ''"
        :key="i"
        @click="tyClick(item)"
      >
        {{ item.FileCategoryName }}
      </li>
    </ul>
    <div class="cont">
      <div class="">
        <el-table border :data="tableData">
          <el-table-column
            align="left"
            prop="FileName"
            label="资料名称"
          ></el-table-column>
          <el-table-column
            align="left"
            prop="CourseName"
            label="课程名称"
          ></el-table-column>
          <!-- <el-table-column align="center" label="资料分类">
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.Category == 0
                    ? "标准"
                    : scope.row.Category == 1
                    ? "VIP"
                    : "免费"
                }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column align="left" label="有效时间" width="200">
            <template slot-scope="scope">
              <div>
                {{ scope.row.FileValidityDateStart | dataYMD }}
                ~ {{ scope.row.FileValidityDateEnd | dataYMD }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row.FileValidityDateEnd
                    ? Math.round(
                        new Date(
                          scope.row.FileValidityDateEnd.replace(/\-/g, '/')
                        ).getTime() / 1000
                      ) >= currentTime &&
                      Math.round(
                        new Date(
                          scope.row.FileValidityDateStart.replace(/\-/g, '/')
                        ).getTime() / 1000
                      ) <= currentTime
                    : true
                "
              >
                <a
                  :href="scope.row.FileUrl"
                  :target="scope.row.FileName"
                  :download="scope.row.FileName"
                  v-if="scope.row.DownloadCategory == 1"
                >
                  <img
                    src="@/assets/images/icon_download.png"
                    height="14"
                    alt=""
                    srcset=""
                  />
                  <p>下载</p>
                </a>
                <a href="#" v-else @click="handlePreview(scope.row.FileUrl)">
                  <img
                    src="@/assets/images/icon_eyes.png"
                    height="14"
                    alt=""
                    srcset=""
                  />
                  <p>预览</p>
                </a>
              </div>
              <div v-else style="color: red">已过期</div>
              <!-- <a :href="`/public/build/web/viewer.html?file=${scope.row.FileUrl}`">1111</a> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length > 0"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="文件预览"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="see_cont">
        <iframe
          class="filename"
          :src="fileUrl"
          width="100%"
          height="600"
          frameborder="1"
          id="ssss"
        ></iframe>
        <!-- <div id="pdf-content" style="height:600px;width: 80%;margin: 0 auto"></div> -->
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { studentQueryStudentFiles, courseQueryUserCourse } from "@/api/personal";
import { courseQueryCourseFileTypeList, courseQueryCourseFileList } from "@/api/course";
export default {
  data () {
    return {
      Imghost: this.GLOBAL.hostUrl,
      tableData: [],
      page: 1,
      pageSize: 10,
      total: 0,
      nowTime: parseInt(new Date().getTime() / 1000),
      dialogVisible: false,
      fileUrl: "",
      currentTime: "",
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      CourseId: "",
      selectVal: "",
      courseList: [],
      types: [],
      FileType: ""
    };
  },
  created () { },
  mounted () {
    this.getCourseQueryUserCourse()
    this.currentTime = this.common.getTodayTimestamp() / 1000;
    // this.init();
  },
  methods: {
    async getCourseQueryUserCourse () {
      let parm = {
        studentId: this.userInfo.Id,
        pageIndex: 1,
        pageSize: 100
      };
      const res = await courseQueryUserCourse(parm);
      if (res.success === true) {
        this.courseList = res.response.data;
        this.CourseId = this.courseList[0].CoursId;
        this.selectVal = this.courseList[0].CourseName;
        this.getCourseQueryCourseFileTypeList()
      } else {
        this.$message.error(res.msg);
      }
    },
    handleChange (obj) {
      this.selectVal = obj.CourseName;
      this.CourseId = obj.CoursId;
      this.types = []
      this.tableData = []
      this.page = 1
      this.getCourseQueryCourseFileTypeList()
    },
    tyClick (obj) {
      this.tableData = []
      this.page = 1
      this.FileType = obj.Id
      this.init()
    },
    //课程列表
    async getCourseQueryCourseFileTypeList () {
      let parm = "?courseId=" + this.CourseId
      const res = await courseQueryCourseFileTypeList(parm);
      if (res.success === true) {
        this.types = res.response
        this.FileType = this.types[0].Id
        this.init()
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.init();
    },
    async init () {
      console.log(window.location.origin)
      let parm = {
        CourseId: this.CourseId,
        FileType: this.FileType,
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      const res = await studentQueryStudentFiles(parm);
      if (res.success === true) {
        this.tableData = res.response.data;
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
    handlePreview (url) {
      // let Base64 = require('js-base64').Base64;
      // window.open('http://121.36.221.33:8012/onlinePreview?url='+encodeURIComponent(Base64.encode(url)));
      let fileName = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'));
      this.fileUrl = "https://admin.pulijiaoyu.org.cn/Upload/" + fileName + "/" + fileName + ".html";
      window.open(this.fileUrl);
    },
    handleClose () {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}

.courseList {
  line-height: 30px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
.myData {
  .cont {
    padding: 30px;
    .time {
      margin-bottom: 30px;
    }
  }
  .types {
    margin: 0 30px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    li {
      min-width: 80px;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      text-align: center;
      color: #747774;
      font-size: 14px;
      // margin-right: 20px;
      border-radius: 30px;
      background-color: #fff;
      cursor: pointer;
    }
    .active {
      color: #1288f4;
      // background-color: #1288f4;
    }
  }
  .personal_title {
    height: auto;
    padding: 18px 30px;
    display: flex;
    justify-content: space-between;
  }
  .courseList_hover {
    width: 217px;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    padding: 0 10px;
    border: 1px solid #dcdfe6;
    color: #606266;
    font-size: 14px;
  }
}
/deep/.el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0 0;
    background-color: #1288f4;
    // padding: 0;
    .el-dialog__title {
      color: #ffffff;
      // height: 60px;
      // line-height: 60px;
      // padding: 0 30px;
    }
  }
}
</style>